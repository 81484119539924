import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

const Component = {
  init() {
    this.customersSlider()
  },
  customersSlider() {
    const customersSlider = $('.splide.js-customers-slider')
    if (customersSlider.length) {
      customersSlider.each(function () {
        const speed = this.hasAttribute('data-reverse') ? -1 : 1

        new Splide(this, {
          type: 'loop',
          drag: 'free',
          focus: 0.8,
          arrows: false,
          pagination: false,
          autoWidth: true,
          perPage: 1,
          autoScroll: {
            speed: speed,
          },
        }).mount({ AutoScroll })
      })
    }
  },
}

$(() => {
  Component.init()
})
